@charset 'utf-8';

// (1) Settings: Globally-available settings and config options
@import 'settings/type';

// (2) Tools: Helper functions and public mixins
@import 'tools/mixins';

// (3) Generics: Far reaching selectors
@import 'generics/generics';

// (4) Base: Element selectors
@import 'base/base';

// (5) Layouts: Structural patterns; No cosmetics. Start using class names here.
// @import 'layouts/layouts';

// (6) Components: Designed UI units
// @import 'components/components';

// (7) Utilities: Overrides, helpers; These trump the above rules.
// @import 'utilities/utilities';
